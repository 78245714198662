/* CSS PAGE DETAILFILMS */
.film-Detail{
    font-size: 16px;
    font-weight: 400;
    color: white;
    padding-top: 76px;
}
.img-detailFilm{
    width: 300px;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 1px 1px 15px 5px #888888;
}
.film-detail-content{
    background-color: #08080885;
    box-shadow: 1px 1px 10px 7px #888888;
    border-radius: 20px;
}
.film-detail-content .btn-update:hover{
    background-color: white;
}
.film-Detail .nav-pills .nav-link{
    background-color: inherit;
    color: white;
}
.film-Detail .nav-pills .nav-link.active{
    color: black;
    background-color: orange;
}
#pills-tabContent-detail{
    padding: 10px;
    box-shadow: 1px 1px 10px 7px #888888;
    background-color: #08080885;
    border-radius: 15px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab{
    opacity: 0.5;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active{
    opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active .img-logo-cinema{
    border-radius: 50%;
    box-shadow: -1px 1px 15px 10px white;
}
.ant-tabs-tab-btn{
    width: 100%;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab{
    text-align: left !important;
}
.ant-tabs-ink-bar{
    background-color: orange !important;
}
.ant-tabs-content-holder{
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
}
.ant-tabs-content-holder::-webkit-scrollbar{
    width: 3px;
}
.ant-tabs-content-holder::-webkit-scrollbar-thumb{
    background-color: orange;
}
.ant-tabs-content-holder::-webkit-scrollbar-track{
    background-color: white;
}
.showtime-detail{
    width: 700px;
    border-bottom: 1px solid #efa318;
}
.showtime-detail .btn-time:hover{
    background-color: white;
}
.detail-reviews{
    background-color: inherit;
    color: white;
    padding: 20px 30px;
    border-radius: 10px;
}


/* RESPONSIVE PAGE DETAIL */
@media (max-width:991px){
    .img-detailFilm{
        margin: auto;
    }
    .detail-tenHTR{
        display: none;
    }
}
