/* CSS MAIN APP COMPONENT */
.mainApp{
    /* height: 650px; */
    /* background-image: url(../../asset/image/bg-app.jpg); */
    background-size: cover;
    padding-top: 100px;
    box-shadow: 1px 1px 10px 1px #888888;
}
.mainApp .app-content{
    text-align: justify;
    padding: 120px 30px;
}
.mainApp .app-content .app-btn:hover{
    background-color: orange;
    border: 1px solid orange;
}
.mainApp .app-slide-content{
    width: 70%;
    height: 80%;
    margin: auto;
    overflow: hidden;
    border: 5px solid black;
    border-radius: 20px;
    box-shadow: 1px 1px 10px 1px #888888;
}
/* RESPONSIVE MAINAPP */
@media (min-width: 991px){
    .mainApp .app-slide{
        padding-left: 10%;
    }
}
@media (max-width:767px){
    .mainApp{
        padding: 0;
    }  
}
