/* CSS MEDIA COMPONENT */
.media{
    /* background-color: #f7f6f6; */
    padding-top: 80px;
    padding-bottom: 100px;
    color: white;
}
.media .nav-pills .nav-link{
    background-color: inherit;
    color: white;
}
.media .nav-pills .nav-link.active{
    color: black;
    background-color: white;
    box-shadow: 1px 1px 10px 3px black inset;
}

/* css phần blog điện ảnh  */
#pills-blog-media .card{
    height: 400px;
    border: none;
    border-radius: 15px;
    /* background-color: #080808c4;
    box-shadow: 1px 1px 10px 7px #888888; */
    background-color: white;
    box-shadow: 1px 5px 30px 3px black inset;
    overflow: hidden;
}
#pills-blog-media .card-img-top{
    height: 250px;
    border-radius: 10px;
}
#pills-blog-media .animation-overlay{
    border-radius: 10px;
}
#pills-blog-media .card-body{
    padding: 10px 0;
}
#pills-blog-media .card-body .card-title{
    color: black;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
}
#pills-blog-media .card-body .card-title:hover{
    color: red;
}
#pills-blog-media .card-body .card-text{
    color: black;
}

/* css phần reviews */
/* #pills-review-media .img-animation img{
    border-radius: 5px;
    box-shadow: 1px 1px 3px 3px #888888;
} */
#pills-review-media{
    border-radius: 20px;
    background-color: white;
    box-shadow: 1px 5px 30px 3px black inset;
}
#pills-review-media .content-title{
    color: black;
}
#pills-review-media .content-title:hover{
    color:red;
}
#pills-review-media .content-review{
    color: black;
}
/* #pills-review-media .btn-review{
    box-shadow: 1px 1px 3px 3px #888888;
} */
#pills-review-media .btn-review:hover{
    background-color: orange;
    border: 1px solid orange;
}

/* css phần khuyến mãi */
#carouselMediaControls img{
    border-radius: 15px;
    box-shadow: 1px 1px 10px 5px #888888;
}
#carouselMediaControls .animation-overlay{ 
    padding: 30% 10%;
    border-radius: 15px;
}
.img-animation:hover .animation-overlay{
    display: block;
    background-color: rgba(20,50,93,.8);
}
.animation-overlay h4, .animation-overlay h6{
    color: white;
}

/* RESPONSIVE MEDIA */
@media (max-width: 479px){
    .pills-tab-movie .nav-item{
        width: 100%;
    }
}