/* CSS FORM LOGIN/REGISTER */
/* formLog css sử dụng chung với Login */
/* CSS IMG */
.imgLogo{
    width: 200px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 50px;
}
.social-Log{
    width: 100%;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}
.img-social-log{
    width: 100%;
    height: 40px;
}