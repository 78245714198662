/* CSS CHECKOUT PAGE */
.check-out-page{
    font-size: 16px;
    font-weight: 400;
    color: white;
    /* background-image: url(../../asset/image/bg-night-home-1.jpg); */
    padding-top: 76px;
}
.check-out-page .screen{
    border-top: 10px solid white;
    width: 70%;
    text-align: center;
    margin: 30px auto;

}
.thongTinPhim, .thongTinRap, .thongTinPay{
    padding: 10px;
    border-radius: 10px;
    background-color: #08080885;
    box-shadow: 1px 1px 10px 1px #888888;
}
.thongTinRap{
    padding: 20px;
}
.checkout-text-info{
    color: white;
    margin-left: 10px;
}
.check-out-page .info-time{
    font-size: 13px !important;
}
.checkout-button-buy{
    width: 100%;
    font-size: 20px;
    text-align: center;
    background-color: rgb(6, 153, 6);
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 1px 1px 10px 1px #888888;
}
.checkout-button-buy:hover{
    background-color: orange;
    color:white;
}
.ghe{
    width: 35px;
    height: 35px;
    margin: 5px;
    background-color: rgb(125, 120, 120);
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}
.gheDaDat{
    background-color: rgb(225, 67, 67) !important;
    cursor: no-drop;
}
.gheVip{
    background-color: rgb(255, 165, 0);
}
.gheDangDat{
    background-color: rgb(10, 195, 10);
}
.gheMinhDat{
    color: orange;
    background-color: papayawhip !important;
}

/* RESPONSIVE PAGE CHECKOUT */
@media (max-width: 1199px){
    .ghe{
        width: 28px;
        height: 28px;
        font-size: 16px;
        display: inline-flex;
        justify-content: center;
    }
}
@media (max-width: 991px){
    .ghe{
        width: 25px;
        height: 25px;
        font-size: 14px;
        display: inline-flex;
        justify-content: center;
    }
}
@media (max-width: 779px){
    .ghe{
        width: 20px;
        height: 20px;
        font-size: 10px;
        display: inline-flex;
        justify-content: center;
    }
}
@media (max-width: 575px){
    .ghe{
        width: 18px;
        height: 18px;
        font-size: 10px;
        display: inline-flex;
        justify-content: center;
    }
}
@media (max-width: 495px){
    .ghe{
        width: 15px;
        height: 15px;
        font-size: 10px;
        display: inline-flex;
        justify-content: center;
    }
}

