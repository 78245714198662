/* CSS PAGE USER ACCOUNT */
.user-account-page{
    padding-top: 76px;
}
.user-account{
    /* background-color: #f7f6f6; */
    padding-top: 70px;
    padding-bottom: 50px;
}
.user-account-page .nav-pills .nav-link{
    background-color: papayawhip;
    color: black;
}
.user-account-page .nav-pills .nav-link.active{
    box-shadow: 1px 1px 10px 3px #888888;
    background-color: orange;
    color: white;
}
.user-account .tab-content{
    /* background-color: rgba(20,50,93,.9); */
    background-color: #08080885;
    padding: 70px;
    border-radius: 20px;
    box-shadow: 1px 1px 10px 7px #888888;
}
.user-account .tab-content .text-input{
    text-align: center;
    margin-bottom: 10px;
    padding: 8px 20px;
    background-color: orange;
    border-radius: 10px;
}
.user-account .tab-content .text-warning{
    background-color: white;
    margin-bottom: 10px;
    text-align: center;
}
.btn-update{
    width: 150px;
}
.btn-update:hover{
    background-color: orange;
    color: black;
    border: 1px solid orange;
}
.user-account span{
    font-size: 16px;
    font-weight: 600;
    padding-left: 20px;
}
#text-notice{
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    border-radius: 2px;
}
.chiTietRap{
    background-color: inherit;
    padding: 10px;
    border-radius: 15px;
}
#pills-profile-user .img-animation .card-img-top{
    border-radius: 10px;
}
.chiTietRap .ngayDat{
    border-bottom: 1px solid white;
    padding: 10px 0;
    font-weight: 600;
}
.chiTietVe{
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.chiTietVe::-webkit-scrollbar{
    width: 3px;
}
.chiTietVe::-webkit-scrollbar-thumb{
    background-color: orange;
}
.chiTietVe::-webkit-scrollbar-track{
    background-color: white;
}

/* RESPONSIVE PAGE USER ACCOUNT */
@media (max-width:1199px){
    .user-account .tab-content .text-input{
        padding: 8px 0;
    }
}
@media (max-width:779px){
    .user-account .tab-content{
        padding: 70px 0;
    }
    
}