/* CSS HEADER COMPONENT */
.headerMovie{
    position: fixed;
    width: 100%;
    background-color: #000000bf;
    /* background-color: #ffffffe7; */
    box-shadow: 1px 1px 10px 1px #888888;
    z-index: 999;
}
.navbar-toggler-icon{
    color: white;
}
.navbar-text{
    display: flex;
    font-size: 16px;
}
.navbar-text .nav-item .nav-link{
    color: white;
}
.navbar-text .nav-item .nav-link:hover{
    color: red;
}
.logo-top{
    margin-right: 250px;
}
.user-login{
    display: flex;
}
.btn-user{
    width: auto;
    height: 38px;
    text-align: center;
    background-color: #28a745;
    border-radius: 10px;
    border: none;
}
.btn-user:hover{
    background-color: orange;
    
}
.btnLog{
    width: 110px;
    height: 38px;
    background-color: #28a745;
    border-radius: 10px;
    border: none;
}
.btnLog:hover{
    background-color: orange;
}
.user-login-dropdown{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.img-user-login{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.dropdown-menu{
    box-shadow: 5px 5px 15px 3px #888888;
}
.btn-user-event:hover{
    background-color: green;
    color: white !important;
}
.searchMovie{
    border: 1px solid #bdbbbb;
    border-radius: 15px;
}
.input-search{
    background-color: inherit;
    border: none;
    border-radius: 15px;
}
.form-control:focus{
    box-shadow: none !important;
}
.btn-search{
    color: white;
    background-color: inherit;
    border: none;
    border-left: 1px solid #bdbbbb;
    
}


/* RESPONSIVE */
@media (max-width: 1199px){
    .logo-top{
        margin-right: 0;
    }
}
@media (max-width: 991px){
    .navbar-collapse{
        display: flex;
        justify-content: space-between;
    }
    .navbar-text{
        justify-content: flex-start;
    }
    .form-inline{
        display: block;
    }
    .searchMovie{
        margin-bottom: 10px;
    }
    .user-login{
        display: block;
    }
    .form-inline .btnLog{
        width: 100%;
        margin-bottom: 10px;
    }
}
@media (max-width: 575px) {
    .searchMovie .btn-search{
        display: none;
    }
}


