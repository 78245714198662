/* CSS CAROUSEL COMPONENT */
.carouselMovie{
    padding-top: 76px;
    width: 90%;
    margin: 0px auto;
}
#carouselMovieControls{
    /* height: 600px; */
    overflow: hidden;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: 1px 1px 10px 7px #888888;
}
#carouselMovieControls .carousel-item{
    height: 600px;
    display: block;
}
.carousel-caption{
    top: 40%;
    bottom: 45%;
    left: 45%;
    right: 45%;
    display: none;
}
.icon-play{
    border: none;
    background-color: inherit;
}
.carousel-item:hover .carousel-caption{
    display: block;
}
.ant-carousel .slick-dots li button{
    width: 16px !important;
    height: 16px !important;
    border-radius: 50% !important;
}
.ant-carousel .slick-dots li.slick-active button{
    background-color: orange !important;
}
/* Modal Carousel */
.ant-modal{
    width: 800px !important;
}
.ant-modal-body{
    padding: 0 !important;
    border: 3px solid orange;
}
.ant-modal-footer{
    display: none;
}