/* CSS LISTFILMS COMPONENT */
.listfilms{
    margin-top: 70px;
    padding-bottom: 50px;
}
.listfilms .nav-pills .nav-link{
    background-color: inherit;
    color: white;
}
.listfilms .nav-pills .nav-link.active{
    /* box-shadow: 1px 1px 10px 7px #888888; */
    color: black;
    background-color: white;
    box-shadow: 1px 1px 10px 3px black inset;
   
}
.listfilms .carousel-inner{
    overflow: unset;
}
.carousel-item .card{
    height: 430px;
    border: none;
    border-radius: 10px;
    background-color: white;
    box-shadow: 1px 5px 30px 3px black inset;
}
.carousel-item .card .card-body{
    text-align: center;
    color: black;
    padding: 6px !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.carousel-item .card-img-top{
    height: 350px;
    border-radius: 10px;
}
.carousel-item .animation-overlay{
    border-radius: 10px;
}
.carousel-item .card-body .card-title{
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
}
.listfilms .img-animation:hover .animation-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
}
.listfilm-btn-buy{
    padding: 10px 30px;
    background-color:rgb(6, 153, 6);
    color: white;
    font-size: 20px;
}
.listfilm-btn-buy:hover{
    background-color: rgb(241, 123, 12);
    color: white;
}
.film-age{
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    background-color: rgb(241, 123, 12);
}

/* RESPONSIVE LIST PHIM */
@media (max-width:991px){
    .col-sm-12,.col-md-6{
        margin-bottom: 20px;
    }
}