/* CSS FORM LOGIN/REGISTER */
.formLog{
    /* height: 600px; */
    position: relative;
    color: white;
    background-color: rgba(20,50,93,.9);
    padding: 30px 20px;
    text-align: center;
    border-radius: 20px;
}
.log-icon-x{
    position:absolute;
    width: 25px;
    height: 25px;
    top: 10px;
    right: 10px;
}
/* .social-Log .kep-login-facebook{
    width: 100%;
    padding: 5px;
    border-radius: 5px !important;
    text-transform: inherit;
} */
.forgotPassword{
    color: white;
}
.forgotPassword:hover{
    color: orange;
    text-decoration: underline;
}
.text-notice-register{
    color: white;
}
.text-notice-register:hover{
    color: orange;
    text-decoration: underline;
}
.modal-login-success{
    text-align: center;
    width: 400px;
}
.modal-login-success .modal-content{
    height: 230px;
    box-shadow: 2px 2px 50px 5px black inset;
    border-radius: 15px;
}
.modal-login-success .modal-body{
    font-size: 20px;
    color: red;
}
.modal-login-success .img-successful{
    width: 70px;
    height: 70px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.modal-login-success .modal-footer{
    padding: 0.5rem !important;
}
.modal-footer{
    border-top: none;
}
/* CSS IMG */
.imgLogo{
    width: 200px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 50px;
}
.social-Log{
    width: 100%;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
}
.img-social-log{
    width: 100%;
    height: 40px;
}